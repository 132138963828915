import React from "react";
import { Link } from "gatsby";
import jet from "../img/jetblue-logo-color.svg";
import via from "../img/via-logo-color.svg";
import usta from "../img/usta-logo-color.svg";
import patr from "../img/patriots-icon-color.svg";
import { Container, Box, Image, T4 } from "../styles/Styles";

const stories = [
  {
    id: "jetblue",
    text: `jetBlueはtomorrow.ioを導入して、他の人には分からない気象予報が把握できています`,
    thumb: jet
  },
  {
    id: "usta-microweather-case-study",
    text:
      `NTCが、とらえどころのないデータといえる気象データの利用において、MicroWeather予報で可能なことを紹介してくれました。`,
    thumb: usta
  },
  {
    id: "gillette-stadium-and-the-new-england-patriots",
    text: `もし雷が土地に落ちる可能性がある場合、皆を避難場所へ誘導するためにも、その危険性を事前に把握する必要があります。tomorrow.ioのツールはいつそのような状況が発生するのかと、過ぎ去るのかを教えてくれます。`,
    thumb: patr
  },
  {
    id: "via-microweather-case-study",
    text: `tomorrow.ioの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。tomorrow.ioのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。`,
    thumb: via
  }
];

export default props => (
  <Container>
    <T4 textAlign="center" mb={5}>他の導入例もご参照ください</T4>

    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      {stories
        .filter(story => story.id !== props.id)
        .slice(0, 3)
        .map(story => (
          <Box px={4}
               py={3}
               mb={4}
               bg="white"
               border="1px solid #ebebeb"
               boxShadow="0 10px 20px rgba(0,0,0,.1)"
               display="flex"
               borderRadius={2}
               flexDirection="column"
               width={[1, "30%"]}
               key={story.id}
          >
            <Box display="flex" justifyContent="center" alignItems="center" minHeight={140}>
              <Image maxHeight={50} maxWidth={160} src={story.thumb} alt=""/>
            </Box>
            <Box mb={1} color="black" dangerouslySetInnerHTML={{ __html: story.text }}/>
            <Box as={Link} to={`/customer-stories/${story.id}/`} mt="auto" fontWeight={800}>もっと詳しく ›</Box>
          </Box>
        ))}
    </Box>
  </Container>
);