import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import RelatedStories from "../../components/RelatedStories";
import iconQuote from "../../img/icon-quote.png";
import person from "../../img/ari-luks-hex.png";

const hero =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/case-via-hero.jpg";
const logo =
    "https://cms2.climacell.co/wp-content/uploads/2019/02/via-logo-color.png";

export default props => (
    <Layout>
      <SEO
          title="VIA ケーススタディ | ClimaCell"
          image={hero}
          pathname={props.location.pathname}
      />
      <section className="c-section-hero">
        <div className="row mx-0">
          <div
              className="col-lg-3 px-0"
              style={{
                minHeight: 600,
                background: `url(${hero}) no-repeat center / cover`,
              }}
          />
          <div className="col-lg-7 d-flex align-items-center">
            <div className="c-shifted-mob w-100 pb-4">
              <div className="d-flex align-items-center mb-5 pt-2 pt-lg-0">
                <img className="mr-3 mb-2" width="100" src={logo} alt="" />
                <span>Case Study</span>
              </div>
              <h1 className="h-2 mb-4">ライド・シェアリング（相乗り）の典型を変える</h1>
              <div className="fs-lg">
                Viaはライド・シェアリングのパイオニアで、サービスを最適化するために詳細な気象予報を取り入れ、リアルタイムの気象変化に合わせたオペレーションの決定を報告します。正確性と道路レベルに焦点をあてたデータを提供するClimaCellを、パートナーとして選びました。
              </div>
            </div>
          </div>
        </div>
        <div className="text-sideways d-none d-md-block">
          42°21'02.1" N 71°03'27.6" W
        </div>
      </section>

      <section className="c-section bg-navy text-white c-section-skew">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">チャレンジ</h2>
            <div className="col-md-9 fs-lg">
              <p>
                Viaは、固定ルートと時間表による規制されたシステムから、完全にダイナミックで、オンデマンドのネットワークに公共交通機関を再設計します。Viaのモバイルアプリは同じ方向に向かう複数の乗客と繋がり、乗車客がシームレスに車両を共有することを可能とします。2013年9月に初めてニューヨークでローンチし、Viaプラットフォームは現在米国と欧州で、Mercedes-Benz VansとViaVanのジョイントベンチャーによって運営されています。Viaのテクノロジーは、最先端のオンデマンドのモビリティーイノベーションを提供するため、公共交通機関インフラにシームレスに導入され、公共交通機関と民間交通機関とのパートナープロジェクトを通じて世界中に展開されています。詳細は、<a href="https://www.ridewithvia.com">ridewithvia.com</a>でご確認ください。
              </p>
              <p>
                Viaのチームは、ライド・シェアリングにおいて天候が大きく影響することを認識しているため、一流の顧客体験を提供するために気象状況をモニターすることは重要であると考えます。Viaの優れたライド・マッチングのアルゴリズムは、渋滞や遅延を避けるため、リアルタイムで車両をダイナミックに誘導します。このような状況は、荒れ模様の天候に深く起因し、分単位の正確な気象状況を把握することは、非常に重要です。
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="c-section">
        <div className="container">
          <div className="row">
            <h2 className="col-md-3 h-3 mb-4">ソリューション</h2>
            <div className="col-md-9 fs-lg">
                Viaはサービスを最適化し、リアルタイムの気象変化に基づいたオペレーションの決定のために、ClimaCellのtomorrow.ioと、詳細にローカライズされた気象予報を頼りとします。
            </div>
          </div>
        </div>
      </section>

      <section className="c-section py-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <img className="my-4" src={iconQuote} alt="" />
              <div className="fs-lg mb-4 lh-lg">
                  ClimaCellの予報は、正確で、リアルタイムの実用的な気づきを提供してくれます。ClimaCellのプロダクトに大変感心し、私たちのオペレーション意思決定や、顧客に質の高いサービスを提供するのに役立っています。
              </div>
              <p className="fs-lg fw-800 mb-4">アリ・ラックス氏、Operations、Via</p>
              <img className="mb-4" src={person} width="100" alt="" />
            </div>
          </div>
          <div className="text-center pt-5">
            <a className="btn btn-lg btn-primary" href="https://www.tomorrow.io/free-trial?bid=2">
                弊社の気象専門家にビジネスについて相談
            </a>
          </div>
        </div>
      </section>

      <section className="c-section">
        <RelatedStories id="via-microweather-case-study" />
      </section>
    </Layout>
);
